import { combineReducers } from "@reduxjs/toolkit";
import { reducer as userReducer } from "../slices/user/userSlice";
import { reducer as jobReducer } from "../slices/job/jobSlice";
import { reducer as candidateReducer } from "../slices/candidateSearch/candidateSlice";
import { reducer as communityReducer } from "../slices/community/communitySlice";
import notificationReducer from "../slices/notifications/notifications";
import chatReducer from "../slices/chat/chatSlice";

const appReducer = combineReducers({
  user: userReducer,
  job: jobReducer,
  candidate: candidateReducer,
  community: communityReducer,
  notifications: notificationReducer,
  chat: chatReducer,
});

const initialState = {
  user: undefined,
  job: undefined,
  candidate: undefined,
  community: undefined,
  notifications: undefined,
};
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = initialState; // Reset state to the initial state
  }

  return appReducer(state, action);
};

export default rootReducer;
