import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotifications,
  markRead,
} from "../redux/slices/notifications/notifications";
import { formatDistanceToNow } from "date-fns"; // For formatting dates
import Nav from "../components/Nav/Nav";
import useNavigation from "../customHooks/useNavigation";

const NotificationsPage = () => {
  const dispatch = useDispatch();
  const handleNotificationClick = useNavigation();

  const { notifications } = useSelector((state) => state.notifications);
  const { user, userType } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchNotifications({ userId: user?._id, userType }));
  }, [dispatch]);

  return (
    <div className=" w-full h-full">
      <Nav />
      <div className="container mx-auto p-5 max-w-3xl">
        <h2 className="text-2xl font-bold mb-4 dark:text-white">
          Notifications
        </h2>

        {notifications.length === 0 ? (
          <p className=" dark:text-gray-400">No notifications available.</p>
        ) : (
          <ul className="space-y-2">
            {notifications?.map((notification) => (
              <li
                key={notification._id}
                onClick={() => {
                  dispatch(markRead(notification._id));
                  handleNotificationClick(notification);
                }}
                className={`p-4 border rounded-lg cursor-pointer hover:bg-purple-400 ${
                  notification.isRead ? "bg-white" : "bg-purple-300 "
                }`}
              >
                <div className="flex justify-between items-center gap-1">
                  <p className=" font-semibold ">{notification.message}</p>
                  <span className="text-gray-500 text-sm truncate">
                    {formatDistanceToNow(new Date(notification.createdAt))} ago
                  </span>
                </div>

                {/* {notification.metadata && (
                  <div className="mt-2">
                    <p className="text-sm text-gray-600">Details:</p>
                    <ul>
                      {Object.entries(notification.metadata).map(
                        ([key, value]) => (
                          <li key={key}>
                            <strong>{key}:</strong> {value}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                )} */}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default NotificationsPage;
