import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../api/axios";

export const fetchCommunities = createAsyncThunk(
  "communities/fetchCommunities",
  async () => {
    const response = await axiosInstance.get("/api/communities");
    return response.data;
  }
);

export const createCommunity = createAsyncThunk(
  "communities/createCommunity",
  async (newCommunity) => {
    const response = await axiosInstance.post("/api/communities", newCommunity);
    return response.data;
  }
);

export const editCommunity = createAsyncThunk(
  "communities/editCommunity",
  async (editedCommunity) => {
    const response = await axiosInstance.put(
      "/api/communities",
      editedCommunity
    );
    return response.data;
  }
);

export const deleteCommunity = createAsyncThunk(
  "communities/deleteCommunity",
  async (communityId) => {
    const response = await axiosInstance.delete(
      `/api/communities/${communityId}`
    );
    return response.data;
  }
);

export const removeMember = createAsyncThunk(
  "communities/removeMember",
  async ({ memberId, communityId }) => {
    const response = await axiosInstance.post(
      `/api/communities/${memberId}/member`,
      { communityId }
    );
    return response.data;
  }
);

export const deletePost = createAsyncThunk(
  "communities/deletePost",
  async (postId) => {
    const response = await axiosInstance.delete(
      `/api/communities/posts/${postId}`
    );
    return response.data;
  }
);

export const upvote = createAsyncThunk(
  "communities/upvote",
  async ({ voterId, postId }) => {
    const response = await axiosInstance.put(
      `/api/communities/${voterId}/upvote`,
      { postId }
    );
    return response.data;
  }
);

export const downvote = createAsyncThunk(
  "communities/downvote",
  async ({ voterId, postId }) => {
    const response = await axiosInstance.put(
      `/api/communities/${voterId}/downvote`,
      { postId }
    );
    return response.data;
  }
);

export const addComment = createAsyncThunk(
  "communities/addComment",
  async ({ comment, commentorType, commentorId, postId }) => {
    const response = await axiosInstance.post(
      `/api/communities/${postId}/comment`,
      { comment, commentorType, commentorId }
    );
    return response.data;
  }
);

export const editComment = createAsyncThunk(
  "communities/editComment",
  async ({ comment, commentId }) => {
    const response = await axiosInstance.put(
      `/api/communities/${commentId}/comment`,
      { comment }
    );
    return response.data;
  }
);
export const deleteComment = createAsyncThunk(
  "communities/deleteComment",
  async ({ commentId, postId }) => {
    const response = await axiosInstance.delete(
      `/api/communities/${commentId}/${postId}/comment`
    );
    return response.data;
  }
);
export const addReply = createAsyncThunk(
  "communities/addReply",
  async ({ reply, commentorType, commentorId, commentId }) => {
    const response = await axiosInstance.put(
      `/api/communities/${commentId}/comment/reply`,
      { reply, commentorType, commentorId }
    );
    return response.data;
  }
);

export const getComments = createAsyncThunk(
  "communities/getComments",
  async (postId) => {
    const response = await axiosInstance.get(
      `/api/communities/${postId}/comment`
    );
    return response.data;
  }
);
