import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import router from "./components/AppRouter";
import ReactGA from "react-ga";

// Initialize ReactGA with your tracking ID
ReactGA.initialize("UA-45799926-9");

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Suspense fallback={<div></div>}>
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      redirectUri={
        process.env.REACT_APP_SERVER_API_URL || "http://localhost:3000"
      }
    >
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </GoogleOAuthProvider>
  </Suspense>
);
