import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosFormDataInstance, axiosInstance } from "../../../api/axios";

export const registerUser = createAsyncThunk(
  "user/registerUser",
  async (userData, thunkAPI) => {
    try {
      const response = await axiosInstance.post(`/api/user/register`, userData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const registerHR = createAsyncThunk(
  "user/registerHR",
  async (userData, thunkAPI) => {
    try {
      const response = await axiosInstance.post(`/api/hr/register`, userData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (userData, thunkAPI) => {
    try {
      const response = await axiosInstance.post(`/api/user/login`, userData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//Check Registration for google
export const checkEmailRegistration = createAsyncThunk(
  "user/checkEmailRegistration",
  async ({ email, userType }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/api/user/checkEmailRegistration/${userType}/${email}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Check Registration for google
export const checkRegistration = createAsyncThunk(
  "user/checkRegistration",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/api/user/google-signin", {
        token,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Complete Google Registration
export const completeRegistration = createAsyncThunk(
  "user/completeRegistration",
  async ({ token, type, referrerId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/api/user/google-signin-complete",
        {
          token,
          type,
          referrerId,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Fetch UserData
export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (userId, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get(`/api/user/${userId}`);
      if (response.data._id) {
        dispatch(updateUser(response.data));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Fetch HRData
export const fetchHR = createAsyncThunk(
  "user/fetchHR",
  async (userId, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get(`/api/hr/${userId}`);
      const { hr } = response.data;
      if (hr) {
        dispatch(updateUser(hr));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Action to update user information
export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (updatedUser) => {
    return updatedUser;
  }
);

// thunk to verify the token
export const verifyToken = createAsyncThunk(
  "auth/verifyToken",
  async (token, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/api/verify-token", { token });
      return response.data; // Assuming the server responds with user data or verification status
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Edit Profile
export const editProfile = createAsyncThunk(
  "auth/editProfile",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        "/api/user/edit-profile",
        formData
      );
      return response.data; // Assuming the server responds with user data or verification status
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update HR Profile
export const updateHRProfile = createAsyncThunk(
  "auth/updateHRProfile",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(
        "/api/hr/update-profile",
        formData
      );

      const { hr } = response.data;
      if (hr) {
        dispatch(updateUser(hr));
      }
      return response.data; // Assuming the server responds with user data or verification status
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveBasicDetails = createAsyncThunk(
  "auth/saveBasicDetails",
  async (basicDetails, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post(`/api/user/saveBasicDetails`, {
        basicDetails,
      });

      const { user } = response.data;
      if (user) {
        dispatch(updateUser(user));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addPseudoName = createAsyncThunk(
  "auth/addPseudoName",
  async ({ userId, userType, pseudoName }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(
        `/api/communities/pseudoName/${userId}`,
        {
          userType,
          pseudoName,
        }
      );

      const { user } = response.data;
      if (user) {
        dispatch(updateUser(user));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendConnectionRequest = createAsyncThunk(
  "auth/sendConnectionRequest",
  async (
    { senderId, senderType, receiverId },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axiosInstance.put(
        `/api/user/connect/${senderId}/${receiverId}/${senderType}`
      );

      const { user } = response.data;
      if (user) {
        dispatch(updateUser(user));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeConnection = createAsyncThunk(
  "auth/removeConnection",
  async (
    { senderId, senderType, receiverId },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axiosInstance.put(
        `/api/user/removeconnection/${senderId}/${receiverId}/${senderType}`
      );

      const { user } = response.data;
      if (user) {
        dispatch(updateUser(user));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveResume = createAsyncThunk(
  "auth/saveResume",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosFormDataInstance.put(
        `/api/user/saveResume`,
        formData
      );

      const { user } = response.data;
      if (user) {
        dispatch(updateUser(user));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveLinksData = createAsyncThunk(
  "auth/saveLinksData",
  async (linksData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post(`/api/user/saveLinksData`, {
        linksData,
      });

      const { user } = response.data;
      if (user) {
        dispatch(updateUser(user));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/api/user/${userId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logout = () => ({
  type: "LOGOUT",
});

const initialState = {
  user: JSON.parse(localStorage.getItem("user")) || null,
  userType: localStorage.getItem("userType") || "",
  isAuthenticated: false,
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: (state, action) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle registerUser thunk
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        // state.user = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle registerHR thunk
      .addCase(registerHR.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerHR.fulfilled, (state, action) => {
        state.loading = false;
        // state.user = action.payload;
      })
      .addCase(registerHR.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle loginUser thunk
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.userType = action.payload.isHr ? "employer" : "user";
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle user delete thunk
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle fetchuser thunk
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle fetchHR thunk
      .addCase(fetchHR.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHR.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(fetchHR.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Check if Registered email
      .addCase(checkEmailRegistration.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkEmailRegistration.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        // state.userType = action.payload.isHr ? "employer" : "user";
      })
      .addCase(checkEmailRegistration.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Check if Registered for Google login
      .addCase(checkRegistration.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkRegistration.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.userType = action.payload.isHr ? "employer" : "user";
      })
      .addCase(checkRegistration.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Complete Google Login for first time thunk - after selecting the Registration type
      .addCase(completeRegistration.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(completeRegistration.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.userType = action.payload.isHr ? "employer" : "user";
      })
      .addCase(completeRegistration.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //Update user
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload; // Update user information in state
        console.log(action.payload);
        localStorage.setItem("user", JSON.stringify(action.payload)); // Update localStorage
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(verifyToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyToken.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true; // Set authentication status based on server response
      })
      .addCase(verifyToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle error if token verification fails
      })
      //Edit profile
      .addCase(editProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        localStorage.setItem("user", JSON.stringify(action.payload.user));
      })
      .addCase(editProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle error if token verification fails
      })

      //Update HR profile
      .addCase(updateHRProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateHRProfile.fulfilled, (state, action) => {
        state.loading = false;
        // state.user = action.payload.user;
      })
      .addCase(updateHRProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Handle error if token verification fails
      })

      //Save Basic details of User
      .addCase(saveBasicDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveBasicDetails.fulfilled, (state, action) => {
        state.loading = false;
        // state.preferences = action.payload;
      })
      .addCase(saveBasicDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      //Save resume
      .addCase(saveResume.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveResume.fulfilled, (state, action) => {
        state.loading = false;
        // state.preferences = action.payload;
      })
      .addCase(saveResume.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      //Save Links Data
      .addCase(saveLinksData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveLinksData.fulfilled, (state, action) => {
        state.loading = false;
        // state.preferences = action.payload;
      })
      .addCase(saveLinksData.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(sendConnectionRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
      })
      .addCase(removeConnection.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
      });
  },
});

export const { actions, reducer } = userSlice;
export const { resetUser } = userSlice.actions;
export default reducer;
