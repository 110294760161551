import { useNavigate } from "react-router-dom";

const useNavigation = () => {
  const navigate = useNavigate();

  const handleNotificationClick = (notification) => {
    const { type, metadata } = notification;

    switch (type) {
      case "job_application":
        navigate(
          `/jobs/${encodeURIComponent(
            metadata.jobTitle.replace(/\s+/g, "-").toLowerCase()
          )}/${metadata.jobId}`
        );
        break;
      case "profile_view":
        navigate(`/profile/${metadata.userId}`);
        break;
      case "status_update":
        navigate("/jobprofile", { state: { tab: "applied jobs" } });
        break;
      case "other":
        navigate(`/notifications/${metadata.notificationId}`);
        break;
      default:
        navigate("/notifications");
        break;
    }
  };

  return handleNotificationClick;
};

export default useNavigation;
