import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { connectSocket, disconnectSocket } from "../socket";
import socket from "../socket";
import { useDispatch, useSelector } from "react-redux";
import {
  addConnectionRequest,
  addNotification,
} from "../redux/slices/notifications/notifications";
import FloatingChat from "../components/FloatingChat";

export default function MainLayout() {
  const { user, userType } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    // Initialize socket connection
    if (user?._id && userType) connectSocket();

    // Register user
    if (user?._id && userType) {
      socket.emit("registerUser", user._id);
    }

    // Clean up on component unmount
    return () => {
      disconnectSocket();
    };
  }, [user?._id, userType, location]);

  useEffect(() => {
    // Listen for the "testDone" event
    socket.on("connection_request", (data) => {
      console.log("Received connection_request:", data);
      dispatch(addConnectionRequest(data));
    });

    socket.on("notification", (data) => {
      console.log("Received notification:", data);
      dispatch(addNotification(data));
    });

    // Cleanup on unmount
    return () => {
      socket.off("connection_request");
      socket.off("notification");
    };
  }, []);

  return (
    <div className="w-screen h-screen overflow-hidden dark:bg-gray-900">
      <main className="w-full h-full">
        <Outlet />
      </main>
      <FloatingChat />
    </div>
  );
}
