import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import {
  toggleChatWindow,
  createChat,
  sendMessage,
} from "../redux/slices/chat/chatSlice"; // Redux actions for opening/closing chat and creating chats

const FloatingChat = () => {
  const dispatch = useDispatch();
  const [minimized, setMinimized] = useState(true); // To control the minimize state
  const { activeChats } = useSelector((state) => state.chat); // Assume you store active chats in redux

  console.log(activeChats);
  const toggleMinimize = () => {
    setMinimized((prev) => !prev);
  };

  const handleNewChat = (user) => {
    dispatch(createChat(user));
  };

  return (
    <div className="fixed bottom-5 right-5 p-4 z-50">
      {activeChats.map((chat) => (
        <div
          key={chat.userId}
          className={`bg-white border rounded-lg shadow-lg w-72 transition-all duration-300 ${
            minimized ? "h-12" : "h-80"
          }`}
        >
          {/* Chat header with user's name and action buttons */}
          <div
            onClick={() => minimized === true && setMinimized(false)}
            className="flex justify-between cursor-pointer rounded-t-lg items-center bg-theme-color text-white px-3 py-2"
          >
            <span className="font-medium">{chat.userName}</span>
            <div className="flex space-x-2">
              <button
                onClick={toggleMinimize}
                className="focus:outline-none text-white"
              >
                {minimized ? "" : <FaChevronDown />}
              </button>
              <button
                onClick={() => dispatch(toggleChatWindow(chat.userId))}
                className="focus:outline-none"
              >
                ❌
              </button>
            </div>
          </div>

          {/* Chat content */}
          {!minimized && (
            <div className="flex flex-col h-full">
              {/* Messages area */}
              <div className="h-52 overflow-y-auto p-2 bg-gray-50">
                {chat.messages.map((msg, index) => (
                  <div
                    key={index}
                    className={`text-black mb-2 px-2 py-1 rounded-lg ${
                      msg.isSender
                        ? "bg-blue-100 text-right"
                        : "bg-gray-100 text-left"
                    }`}
                  >
                    {msg.text}
                  </div>
                ))}
              </div>

              {/* Input for sending a new message */}
              <div className="p-2 border-t">
                <input
                  type="text"
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-purple-300"
                  placeholder="Type your message..."
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // Dispatch action to send message
                      dispatch(sendMessage(chat.userId, e.target.value));
                      e.target.value = "";
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FloatingChat;
