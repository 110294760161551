import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import userdp from "../assets/images/default-dp.svg";
import {
  acceptConnectionRequest,
  declineConnectionRequest,
  removeConnectionRequest,
  setConnectionRequests, // Action to set the connection requests in Redux state
  setConnections, // Action to set the connections in Redux state
} from "../redux/slices/notifications/notifications";
import Nav from "../components/Nav/Nav";
import { axiosInstance } from "../api/axios";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../redux/slices/user/userSlice";
import MessageButton from "../components/Message";
import PageLoader from "../components/Utility/PageLoader";

const baseUrl = process.env.REACT_APP_SERVER_API_URL || "http://localhost:3001";

const ConnectionRequests = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { connectionRequests, connections } = useSelector(
    (state) => state.notifications
  );
  const { user, userType } = useSelector((state) => state.user);
  const navigate = useNavigate();

  // Fetch connection requests and connections when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await axiosInstance.get(
          `/api/user/${user?._id}/${userType}/connect`
        );
        const { updatedUser } = res.data;
        if (updatedUser) {
          dispatch(updateUser(updatedUser));
        }
        // Assuming the API response contains connection requests and connections
        dispatch(setConnectionRequests(res.data?.connectRequests));
        dispatch(setConnections(res.data?.connections)); // Set the connections in the Redux state
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(
          "Failed to fetch connection requests or connections:",
          error
        );
      }
    };

    if (user && userType) {
      fetchData();
    }
  }, [dispatch, user?._id, userType]);

  const handleAccept = (senderId, senderType) => {
    setIsLoading(true);
    dispatch(
      acceptConnectionRequest({
        senderId,
        senderType,
        userId: user?._id,
        userType,
      })
    ).then((action) => {
      if (acceptConnectionRequest.fulfilled.match(action)) {
        dispatch(removeConnectionRequest(senderId));
        setIsLoading(false);
      }
    });
  };

  const handleDecline = (senderId, senderType) => {
    dispatch(
      declineConnectionRequest({
        senderId,
        senderType,
        userId: user?._id,
        userType,
      })
    ).then((action) => {
      if (declineConnectionRequest.fulfilled.match(action)) {
        setIsLoading(false);
      }
    });
  };

  return (
    <div className="w-full h-full">
      <Nav />
      {isLoading && <PageLoader />}
      <div className="container mx-auto p-4 max-w-2xl">
        <h2 className="text-2xl font-bold mb-4 dark:text-white">
          Connection Requests
        </h2>
        <div className="space-y-4">
          {connectionRequests?.length > 0 ? (
            connectionRequests?.map((request) => (
              <div
                key={request?._id || request?.senderId}
                className="flex items-center justify-between gap-2 bg-gray-100 dark:bg-gray-900 dark:text-white dark:border-gray-950 p-2 sm:p-4 rounded-md shadow-md"
              >
                <div className="flex items-center space-x-2 sm:space-x-4 hover:text-theme-color hover:underline hover:border-purple-400 cursor-pointer">
                  <img
                    src={
                      request.senderId?.imageUrl?.startsWith(
                        "https://lh3.googleusercontent.com"
                      )
                        ? request.senderId?.imageUrl || userdp
                        : request.senderId?.imageUrl
                        ? `${baseUrl}/${request.senderId.imageUrl}`
                        : userdp
                    }
                    alt={request.senderId?.name}
                    className="w-12 h-12 rounded-full border border-gray-400 p-1"
                  />
                  <div>
                    <p
                      onClick={() =>
                        navigate(
                          request.senderType === "User"
                            ? `/user-profile/${request.senderId?._id}`
                            : `/hr-profile/${request.senderId?._id}`
                        )
                      }
                      className="text-lg font-semibold truncate hover:text-theme-color dark:text-white hover:underline cursor-pointer"
                    >
                      {request.senderId?.name}
                    </p>
                  </div>
                </div>
                <div className=" flex items-center gap-2">
                  <button
                    className="bg-green-500 text-white px-2 sm:px-4 py-2 rounded-full text-xs sm:text-sm "
                    onClick={() =>
                      handleAccept(request.senderId?._id, request?.senderType)
                    }
                  >
                    Accept
                  </button>
                  <button
                    className="bg-red-500 text-white px-2 sm:px-4 py-2 rounded-full text-xs sm:text-sm"
                    onClick={() =>
                      handleDecline(request.senderId?._id, request?.senderType)
                    }
                  >
                    Decline
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className=" dark:text-gray-400">
              No connection requests available.
            </p>
          )}
        </div>

        {/* Display Accepted Connections */}
        <h2 className="text-2xl font-bold mt-8 mb-4 dark:text-white">
          Your Connections({connections?.length})
        </h2>
        <div className="space-y-4">
          {connections?.length > 0 ? (
            connections?.map((connection) => (
              <div
                key={connection?._id || connection?.connectionId}
                className="flex items-center justify-between bg-gray-100 dark:bg-gray-900 dark:text-white dark:border-gray-950 dark:border p-4 rounded-md shadow-md"
              >
                <div
                  onClick={() =>
                    navigate(
                      connection.connectionType === "User"
                        ? `/user-profile/${connection.connectionId?._id}`
                        : `/hr-profile/${connection.connectionId?._id}`
                    )
                  }
                  className="flex items-center space-x-2 sm:space-x-4 hover:text-theme-color hover:underline hover:border-purple-400 cursor-pointer"
                >
                  <img
                    src={
                      connection.connectionId?.imageUrl?.startsWith(
                        "https://lh3.googleusercontent.com"
                      )
                        ? connection.connectionId?.imageUrl || userdp
                        : connection.connectionId?.imageUrl
                        ? `${baseUrl}/${connection.connectionId?.imageUrl}`
                        : userdp
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = userdp;
                    }}
                    alt={connection.connectionId?.name}
                    className="w-12 h-12 rounded-full border border-gray-400 p-1"
                  />
                  <div>
                    <p className="text-lg font-semibold  dark:text-white  cursor-pointer">
                      {connection.connectionId?.name}
                    </p>
                  </div>
                </div>
                <div className="space-x-4">
                  <MessageButton user={connection.connectionId} />
                </div>
              </div>
            ))
          ) : (
            <p className=" dark:text-gray-400">No connections available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConnectionRequests;
