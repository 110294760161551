// socket.js

import io from "socket.io-client";

// Replace with your server URL
const SERVER_URL = "http://localhost:3001";

// Initialize socket connection
const socket = io(SERVER_URL, {
  autoConnect: false, // Prevent auto connection, manage connection manually
  transports: ["websocket"], // Use WebSocket for the connection
});

// Function to connect to the server
export const connectSocket = () => {
  socket.connect();
  console.log("Socket connected:", socket.id);
};

// Function to disconnect from the server
export const disconnectSocket = () => {
  socket.disconnect();
  console.log("Socket disconnected");
};

// Export the socket instance and connection functions
export default socket;
