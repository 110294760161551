import { createSlice } from "@reduxjs/toolkit";
import { searchCandidates } from "./candidateThunk";

const initialState = {
  candidates: [],
  loading: false,
  error: null,
  searchCriteria: {
    keyword: "",
    city: "",
    skills: [],
    datePosted: "",
    experienceLevel: 0,
    salaryRange: [0, 600000],
  },
};

const candidateSlice = createSlice({
  name: "candidate",
  initialState,
  reducers: {
    setKeyword: (state, action) => {
      state.searchCriteria.keyword = action.payload;
    },
    setCity: (state, action) => {
      state.searchCriteria.city = action.payload;
    },
    setDatePosted: (state, action) => {
      state.searchCriteria.datePosted = action.payload;
    },
    setExperienceLevel: (state, action) => {
      state.searchCriteria.experienceLevel = action.payload;
    },
    setSalaryRange: (state, action) => {
      state.searchCriteria.salaryRange = action.payload;
    },
    setSearchResults: (state, action) => {
      state.candidates = action.payload;
    },
    setSkills: (state, action) => {
      state.searchCriteria.skills = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchCandidates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchCandidates.fulfilled, (state, action) => {
        state.loading = false;
        state.candidates = action.payload.candidates;
      })
      .addCase(searchCandidates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setKeyword,
  setCity,
  setDatePosted,
  setExperienceLevel,
  setError,
  setSalaryRange,
  setSearchResults,
  setSkills,
  setLoading,
} = candidateSlice.actions;
export const { actions, reducer } = candidateSlice;
export default reducer;
