import { createSlice } from "@reduxjs/toolkit";

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    activeChats: [],
  },
  reducers: {
    createChat: (state, action) => {
      const user = action.payload;
      const existingChat = state.activeChats.find(
        (chat) => chat.userId === user._id
      );
      if (!existingChat) {
        state.activeChats.push({
          userId: user._id,
          userName: user.name,
          messages: [],
        });
      }
    },
    toggleChatWindow: (state, action) => {
      state.activeChats = state.activeChats.filter(
        (chat) => chat.userId !== action.payload
      );
    },
    sendMessage: (state, action) => {
      const { userId, text } = action.payload;
      const chat = state.activeChats.find((chat) => chat.userId === userId);
      if (chat) {
        chat.messages.push({ text, isSender: true });
      }
    },
  },
});

export const { createChat, toggleChatWindow, sendMessage } = chatSlice.actions;
export default chatSlice.reducer;
