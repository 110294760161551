import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../api/axios";

// Thunk for Searching jobs-candidate
export const searchCandidates = createAsyncThunk(
  "job/searchCandidates",
  async ({ keyword, city }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();

      console.log(city);

      // Only add searchText and city to the parameters if they are truthy
      if (keyword) params.append("keyword", keyword);
      if (city) params.append("city", city);
      const response = await axiosInstance.get(`/api/hr/searchCandidates`, {
        params,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
