// redux/notificationSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../api/axios";
import { updateUser } from "../user/userSlice";

// Async thunks for accepting and declining requests
export const acceptConnectionRequest = createAsyncThunk(
  "notifications/acceptConnectionRequest",
  async (
    { senderId, senderType, userId, userType },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axiosInstance.post(
        `/api/user/accept-request/${senderId}`,
        { senderType, userId, userType }
      );

      const { user } = response.data;

      if (user) {
        dispatch(updateUser(user));
      }

      return senderId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const declineConnectionRequest = createAsyncThunk(
  "notifications/declineConnectionRequest",
  async (
    { senderId, senderType, userId, userType },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axiosInstance.post(
        `/api/user/decline-request/${senderId}`,
        { senderType, userId, userType }
      );
      const { user } = response.data;

      if (user) {
        dispatch(updateUser(user));
      }
      return senderId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to fetch notifications
export const fetchNotifications = createAsyncThunk(
  "notifications/fetchNotifications",
  async ({ userId, userType }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get(
        `/api/notifications/${userId}/${userType}`
      );

      const { user } = response.data;
      if (user) {
        dispatch(updateUser(user));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to mark notification read
export const markRead = createAsyncThunk(
  "notifications/markRead",
  async (notificationId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/api/notifications/${notificationId}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    notifications: [],
    connectionRequests: [],
    connections: [],
  },
  reducers: {
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setConnectionRequests: (state, action) => {
      state.connectionRequests = action.payload;
    },
    addConnectionRequest: (state, action) => {
      state.connectionRequests.push(action.payload);
    },
    removeConnectionRequest: (state, action) => {
      const index = state.connectionRequests.findIndex(
        (req) => req.senderId._id === action.payload
      );

      if (index !== -1) {
        state.connectionRequests.splice(index, 1);
      }
    },
    setConnections: (state, action) => {
      state.connections = action.payload;
    },
    removeConnection: (state, action) => {
      const idTobeRemoved = state.connectionRequests.find(
        (request) => request.connectionId === action.payload
      );

      if (idTobeRemoved) {
        state.connections = state.connections.filter(
          (request) => request.connectionId !== action.payload
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(acceptConnectionRequest.fulfilled, (state, action) => {
        // Remove the request from connectionRequests when accepted
        const acceptedRequest = state.connectionRequests.find(
          (request) => request.senderId._id === action.payload
        );

        if (acceptedRequest) {
          // Create a new connection object
          const newConnection = {
            connectionType: acceptedRequest.senderType,
            connectionId: acceptedRequest.senderId,
          };

          // Add the new connection to the connections array
          state.connections.push(newConnection);

          // Filter out the accepted request from connectionRequests
          state.connectionRequests = state.connectionRequests.filter(
            (request) => request.senderId._id !== action.payload
          );
        }
      })
      .addCase(declineConnectionRequest.fulfilled, (state, action) => {
        // Remove the request from connectionRequests when declined
        const declinedRequest = state.connectionRequests.find(
          (request) => request.senderId._id === action.payload
        );

        if (declinedRequest) {
          // Filter out the accepted request from connectionRequests
          state.connectionRequests = state.connectionRequests.filter(
            (request) => request.senderId._id !== action.payload
          );
        }
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload.notifications;
        state.loading = false;
      })
      .addCase(markRead.fulfilled, (state, action) => {
        state.notifications = action.payload.notifications;
        state.loading = false;
      });
  },
});

export const {
  addNotification,
  setConnectionRequests,
  addConnectionRequest,
  removeConnectionRequest,
  setConnections,
  removeConnection,
} = notificationSlice.actions;
export default notificationSlice.reducer;
