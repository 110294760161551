import React, { Suspense, lazy } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import ConnectionRequests from "../pages/ConnectionRequests";
import NotificationsPage from "../pages/NotificationsPage";

const ChooseRegistrationType = lazy(() =>
  import("../components/ChooseRegistrationType")
);
const UserLayout = lazy(() => import("../layout/UserLayout"));

// Employer Pages
const Empdashboard = lazy(() => import("../pages/Employer/Empdashboard"));
const Userdashboard = lazy(() =>
  import("../pages/Employer/DashboardData/Userdashboard")
);
const Profile = lazy(() => import("../pages/Employer/DashboardData/Profile"));
const Myjobs = lazy(() => import("../pages/Employer/DashboardData/Myjobs"));
const Submitjobs = lazy(() =>
  import("../pages/Employer/DashboardData/Submitjobs")
);
const Applicantsjobs = lazy(() =>
  import("../pages/Employer/DashboardData/Applicantsjobs")
);
const ShortlistCandidate = lazy(() =>
  import("../pages/Employer/DashboardData/Shortlistcandidate")
);

const Changepassword = lazy(() =>
  import("../pages/Employer/DashboardData/Changepassword")
);
const ChangepasswordUser = lazy(() => import("../pages/User/Changepassword"));
const Logout = lazy(() => import("../pages/Employer/DashboardData/Logout"));
const PostNewJob = lazy(() =>
  import("../components/Employer/DashboardData/SubmitJobsComps/PostNewJob")
);
const EmployerLayout = lazy(() => import("../layout/EmployerLayout"));
const Settings = lazy(() => import("../pages/Settings"));
const Userpage = lazy(() => import("../pages/User/Userpage"));
const UserDashboard = lazy(() => import("../pages/User/UserDashboard"));
const SearchedJobs = lazy(() => import("../pages/SearchedJobs"));
const JobDetails = lazy(() => import("../pages/JobDetails"));
const NotFound = lazy(() => import("../pages/NotFound"));
const Badge = lazy(() => import("../pages/User/Badge"));
const Group = lazy(() => import("../pages/User/Group"));
const Mentor = lazy(() => import("../pages/User/Mentor"));
const Mentorsingle = lazy(() => import("../pages/User/MentorSingle"));
const Analytics = lazy(() => import("../pages/Analytics"));
const Socialaccount = lazy(() => import("../pages/Socialaccount"));
const Payment = lazy(() => import("../pages/Payment"));
const UserProfile = lazy(() => import("../pages/User/UserProfile"));
const JobDashboard = lazy(() => import("../pages/User/JobDashboard"));
const JobProfile = lazy(() => import("./User/JobProfile"));
const EmployerProfile = lazy(() => import("../pages/Employer/EmployerProfile"));
const ViewApplications = lazy(() =>
  import("./Employer/DashboardData/ViewApplications")
);
const Community = lazy(() => import("../pages/Community"));
const LandingPage = lazy(() => import("../pages/LandingPage"));
const ForgotPassword = lazy(() => import("./ForgotPassword"));
const ResetPassword = lazy(() => import("./ResetPassword"));
const TokenDashboard = lazy(() => import("../pages/TokenDashboard"));
const ContactUs = lazy(() => import("../pages/ContactUs"));
const CommunityLayout = lazy(() => import("../layout/CommunityLayout"));
const SearchResultsPage = lazy(() => import("./SearchResultsPage"));
const CommunityPage = lazy(() => import("../pages/CommunityPage"));

const Login = lazy(() => import("../pages/Login"));
const Register = lazy(() => import("../pages/Register"));
const AdminPage = lazy(() => import("../pages/AdminPage"));

const PageLoader = lazy(() => import("./Utility/PageLoader"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <Suspense
          fallback={
            <div>
              <PageLoader />
            </div>
          }
        >
          <MainLayout />
        </Suspense>
      }
    >
      <Route
        path=""
        element={
          <Suspense fallback={<PageLoader />}>
            <LandingPage />
          </Suspense>
        }
      />
      <Route
        path="prokutumb-admin"
        element={
          <Suspense fallback={<PageLoader />}>
            <AdminPage />
          </Suspense>
        }
      />
      <Route
        path="login"
        element={
          <Suspense fallback={<PageLoader />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        path="register"
        element={
          <Suspense fallback={<PageLoader />}>
            <Register />
          </Suspense>
        }
      />
      <Route
        path="choose-registration-type"
        element={
          <Suspense fallback={<PageLoader />}>
            <ChooseRegistrationType />
          </Suspense>
        }
      />
      <Route
        path="/jobprofile"
        element={
          <Suspense fallback={<PageLoader />}>
            <JobProfile />
          </Suspense>
        }
      />
      <Route
        path="/connectionrequests"
        element={
          <Suspense fallback={<PageLoader />}>
            <ConnectionRequests />
          </Suspense>
        }
      />
      <Route
        path="/notifications"
        element={
          <Suspense fallback={<PageLoader />}>
            <NotificationsPage />
          </Suspense>
        }
      />
      <Route
        path="/contactus"
        element={
          <Suspense fallback={<PageLoader />}>
            <ContactUs />
          </Suspense>
        }
      />
      <Route
        path="/searchbar-results"
        element={
          <Suspense fallback={<PageLoader />}>
            <SearchResultsPage />
          </Suspense>
        }
      />
      <Route
        path="/search-results"
        element={
          <Suspense fallback={<PageLoader />}>
            <SearchedJobs />
          </Suspense>
        }
      />
      <Route
        path="/logout"
        element={
          <Suspense fallback={<PageLoader />}>
            <Logout />
          </Suspense>
        }
      />
      <Route
        path="/jobs/:jobTitle/:id"
        element={
          <Suspense fallback={<PageLoader />}>
            <JobDetails />
          </Suspense>
        }
      />
      <Route
        path="/employer/:jobTitle/applicants/:jobId"
        element={
          <Suspense fallback={<PageLoader />}>
            <ViewApplications />
          </Suspense>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <Suspense fallback={<PageLoader />}>
            <ForgotPassword />
          </Suspense>
        }
      />
      <Route
        path="/reset-password/:token"
        element={
          <Suspense fallback={<PageLoader />}>
            <ResetPassword />
          </Suspense>
        }
      />
      <Route
        path="/user-profile/:userId"
        element={
          <Suspense fallback={<PageLoader />}>
            <UserProfile />
          </Suspense>
        }
      />
      <Route
        path="/hr-profile/:employerId"
        element={
          <Suspense fallback={<PageLoader />}>
            <EmployerProfile />
          </Suspense>
        }
      />
      <Route
        path="/jobdashboard"
        element={
          <Suspense fallback={<PageLoader />}>
            <JobDashboard />
          </Suspense>
        }
      />
      <Route
        element={
          <Suspense fallback={<PageLoader />}>
            <UserLayout />
          </Suspense>
        }
      >
        <Route
          path="user"
          element={
            <Suspense
              fallback={
                <div>
                  <PageLoader />
                </div>
              }
            >
              <UserDashboard />
            </Suspense>
          }
        >
          <Route
            path="badge"
            element={
              <Suspense
                fallback={
                  <div>
                    <PageLoader />
                  </div>
                }
              >
                <Badge />
              </Suspense>
            }
          />
          <Route
            path="group"
            element={
              <Suspense
                fallback={
                  <div>
                    <PageLoader />
                  </div>
                }
              >
                <Group />
              </Suspense>
            }
          />
          <Route
            path="mentors"
            element={
              <Suspense
                fallback={
                  <div>
                    <PageLoader />
                  </div>
                }
              >
                <Mentor />
              </Suspense>
            }
          />
          <Route
            path="mentordetails"
            element={
              <Suspense
                fallback={
                  <div>
                    <PageLoader />
                  </div>
                }
              >
                <Mentorsingle />
              </Suspense>
            }
          />
          <Route
            path="analytics"
            element={
              <Suspense
                fallback={
                  <div>
                    <PageLoader />
                  </div>
                }
              >
                <Analytics />
              </Suspense>
            }
          />
          <Route
            path=""
            element={
              <Suspense
                fallback={
                  <div>
                    <PageLoader />
                  </div>
                }
              >
                <Userpage />
              </Suspense>
            }
          />
          <Route
            path="settings"
            element={
              <Suspense
                fallback={
                  <div>
                    <PageLoader />
                  </div>
                }
              >
                <Settings />
              </Suspense>
            }
          />

          <Route
            path="payment"
            element={
              <Suspense
                fallback={
                  <div>
                    <PageLoader />
                  </div>
                }
              >
                <Payment />
              </Suspense>
            }
          />
          <Route
            path="password"
            element={
              <Suspense
                fallback={
                  <div>
                    <PageLoader />
                  </div>
                }
              >
                <ChangepasswordUser />
              </Suspense>
            }
          />
          <Route
            path="kutumb-tokens"
            element={
              <Suspense
                fallback={
                  <div>
                    <PageLoader />
                  </div>
                }
              >
                <TokenDashboard />
              </Suspense>
            }
          />
        </Route>
      </Route>

      <Route
        element={
          <Suspense fallback={<PageLoader />}>
            <EmployerLayout />
          </Suspense>
        }
      >
        <Route
          path="employer"
          element={
            <Suspense
              fallback={
                <div>
                  <PageLoader />
                </div>
              }
            >
              <Empdashboard />
            </Suspense>
          }
        >
          <Route
            path=""
            element={
              <Suspense
                fallback={
                  <div>
                    <PageLoader />
                  </div>
                }
              >
                <Userdashboard />
              </Suspense>
            }
          />
          <Route
            path="profile"
            element={
              <Suspense
                fallback={
                  <div>
                    <PageLoader />
                  </div>
                }
              >
                <Profile />
              </Suspense>
            }
          />
          <Route
            path="myjobs"
            element={
              <Suspense
                fallback={
                  <div>
                    <PageLoader />
                  </div>
                }
              >
                <Myjobs />
              </Suspense>
            }
          />
          <Route
            path="submitjobs"
            element={
              <Suspense
                fallback={
                  <div>
                    <PageLoader />
                  </div>
                }
              >
                <Submitjobs />
              </Suspense>
            }
          />
          <Route
            path="applicantjobs"
            element={
              <Suspense
                fallback={
                  <div>
                    <PageLoader />
                  </div>
                }
              >
                <Applicantsjobs />
              </Suspense>
            }
          />
          <Route
            path="shortlistcandidates"
            element={
              <Suspense
                fallback={
                  <div>
                    <PageLoader />
                  </div>
                }
              >
                <ShortlistCandidate />
              </Suspense>
            }
          />

          <Route
            path="changepassword"
            element={
              <Suspense
                fallback={
                  <div>
                    <PageLoader />
                  </div>
                }
              >
                <Changepassword />
              </Suspense>
            }
          />
          <Route
            path="postnewjob"
            element={
              <Suspense
                fallback={
                  <div>
                    <PageLoader />
                  </div>
                }
              >
                <PostNewJob />
              </Suspense>
            }
          />
          <Route
            path="settings"
            element={
              <Suspense
                fallback={
                  <div>
                    <PageLoader />
                  </div>
                }
              >
                <Settings />
              </Suspense>
            }
          />
          <Route
            path="kutumb-tokens"
            element={
              <Suspense
                fallback={
                  <div>
                    <PageLoader />
                  </div>
                }
              >
                <TokenDashboard />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="*"
          element={
            <Suspense
              fallback={
                <div>
                  <PageLoader />
                </div>
              }
            >
              <NotFound />
            </Suspense>
          }
        />
      </Route>

      <Route
        path="/community"
        element={
          <Suspense fallback={<PageLoader />}>
            <CommunityLayout />
          </Suspense>
        }
      >
        <Route
          path=""
          element={
            <Suspense
              fallback={
                <div>
                  <PageLoader />
                </div>
              }
            >
              <Community />
            </Suspense>
          }
        />
        <Route
          path=":communityId"
          element={
            <Suspense
              fallback={
                <div>
                  <PageLoader />
                </div>
              }
            >
              <CommunityPage />
            </Suspense>
          }
        />
      </Route>
    </Route>
  )
);

export default router;
