import React, { useState, useEffect, lazy, Suspense, useRef } from "react";
import { motion } from "framer-motion";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import kutumbToken from "../../assets/images/token.webp";
import { useDispatch, useSelector } from "react-redux";
import { IoSearch } from "react-icons/io5";
import {
  fetchNotifications,
  setConnectionRequests,
} from "../../redux/slices/notifications/notifications";
import { GoBell } from "react-icons/go";

const Sidebar = lazy(() => import("./Sidebar"));
const SearchBar = lazy(() => import("../SearchBar"));
const DarkModeToggle = lazy(() => import("../DarkModeToggle"));
const FiHome = lazy(() =>
  import("react-icons/fi").then((module) => ({ default: module.FiHome }))
);
const MdPersonOutline = lazy(() =>
  import("react-icons/md").then((module) => ({
    default: module.MdPersonOutline,
  }))
);
const MdOutlineSearch = lazy(() =>
  import("react-icons/md").then((module) => ({
    default: module.MdOutlineSearch,
  }))
);

const FaSearchengin = lazy(() =>
  import("react-icons/fa6").then((module) => ({
    default: module.FaSearchengin,
  }))
);
const TiGroup = lazy(() =>
  import("react-icons/ti").then((module) => ({ default: module.TiGroup }))
);
const CiMenuFries = lazy(() =>
  import("react-icons/ci").then((module) => ({ default: module.CiMenuFries }))
);
const AiOutlineTeam = lazy(() =>
  import("react-icons/ai").then((module) => ({ default: module.AiOutlineTeam }))
);

const Nav = ({ bgColor, toggleUserSidebar }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isRequestsOpen, setIsRequestsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const userType = localStorage.getItem("userType");
  const token = localStorage.getItem("token");
  const { connectionRequests, notifications } = useSelector(
    (state) => state.notifications
  );
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const checkMobileScreen = () => {
    setIsMobile(window.innerWidth <= 1500); // Adjust breakpoint as needed
  };

  useEffect(() => {
    if (user && token) {
      dispatch(setConnectionRequests(user?.connectRequests));
      dispatch(fetchNotifications({ userId: user?._id, userType }));
    }
  }, [user]);

  useEffect(() => {
    checkMobileScreen();
    window.addEventListener("resize", checkMobileScreen);
    return () => window.removeEventListener("resize", checkMobileScreen);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsRequestsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <motion.nav
      initial={{ y: location.pathname !== "/" && -100 }}
      animate={{ y: location.pathname !== "/" && 0 }}
      transition={{ duration: location.pathname !== "/" && 1 }}
      className={`${
        location.pathname === "/" ? ` xl:${bgColor} bg-white` : bgColor
      } w-full  dark:bg-gray-800 dark:border-none z-40`}
    >
      <div className="w-full  flex items-center justify-between border-0 py-3 px-2 lg:py-7 lg:px-16 xl:px-28  dark:bg-gray-800  dark:border-none xl:py-8 z-50">
        <div className="flex items-center">
          <div className="flex-shrink-0 text-black text-3xl">
            <img
              className="h-6 sm:h-8 xl:h-12 cursor-pointer"
              src={logo}
              alt="Logo"
              onClick={() =>
                navigate(
                  userType
                    ? userType === "user"
                      ? "/search-results"
                      : "/employer"
                    : "/"
                )
              }
            />
          </div>
        </div>
        {token && (
          <div className="flex items-center ml-3 space-x-3 mr-20">
            <form action="#" className="ml-3 hidden md:block">
              <div className="flex items-center bg-gray-200 rounded-3xl px-5">
                <Suspense fallback={<div></div>}>
                  <MdOutlineSearch size={20} color="gray" className="" />
                </Suspense>
                <Suspense fallback={<div></div>}>
                  <SearchBar />
                </Suspense>
                {/* <input
                  type="text"
                  placeholder="Start typing to search.."
                  className="bg-transparent py-3 px-2  w-80 focus:outline-none"
                /> */}
              </div>
            </form>

            <div className=" items-center ml-3 hidden xl:flex space-x-3 w-full">
              <NavLink
                to={`/${userType}`}
                className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 dark:bg-opacity-20"
              >
                <Suspense fallback={<div></div>}>
                  <FiHome
                    size={28}
                    title="My Dashboard"
                    className="feather-home text-gray-400 text-lg"
                  />
                </Suspense>
              </NavLink>
              {userType === "user" && (
                <NavLink
                  to={`/search-results`}
                  title="Search Jobs"
                  className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 dark:bg-opacity-20"
                >
                  <Suspense fallback={<div></div>}>
                    <FaSearchengin
                      size={28}
                      className="feather-home text-gray-400 text-lg"
                    />
                  </Suspense>
                </NavLink>
              )}
              {userType === "employer" && (
                <NavLink
                  to="/employer/applicantjobs"
                  title="Shortlisted Candidates"
                  className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 dark:bg-opacity-20"
                >
                  <Suspense fallback={<div></div>}>
                    <MdPersonOutline
                      size={28}
                      className="feather-user text-gray-400 text-lg"
                    />
                  </Suspense>
                </NavLink>
              )}
              <NavLink
                to="/community"
                title="Communities"
                className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 dark:bg-opacity-20 relative"
              >
                <Suspense fallback={<div></div>}>
                  <TiGroup
                    size={28}
                    className="feather-shopping-bag text-gray-400 text-lg"
                  />
                </Suspense>
              </NavLink>
            </div>
          </div>
        )}
        <div>
          {token && (
            <div className="hidden xl:flex items-center ml-3 space-x-2 md:space-x-5">
              <Link
                title="Tokens"
                to={`/${userType}/kutumb-tokens`}
                className=" bg-white border-2  border-theme-color hover:bg-purple-200 transition-colors text-black  p-1 px-3 rounded-full flex items-center gap-2"
              >
                <img className=" h-5" src={kutumbToken} />
                <p className=" text-sm font-semibold ">{user?.kutumbTokens}</p>
              </Link>

              <div
                onClick={() => navigate("/connectionrequests")}
                className=" p-1  rounded-full cursor-pointer hover:bg-gray-100 dark:bg-opacity-20 relative"
              >
                <Suspense fallback={<div></div>}>
                  <AiOutlineTeam
                    title="Network"
                    className="text-theme-color font-bold text-xl md:text-2xl lg:text-3xl"
                  />
                </Suspense>
                {connectionRequests?.length > 0 && (
                  <div className=" absolute  h-5 w-5 text-[10px] bg-red-500 aspect-square px-1 flex items-center justify-center font-semibold text-white rounded-full top-0 -right-2">
                    <span>{connectionRequests?.length}</span>
                  </div>
                )}
              </div>
              <div
                title="Notifications"
                onClick={() => navigate("/notifications")}
                className={`rounded-lg cursor-pointer relative`}
              >
                {notifications?.filter((notif) => !notif.isRead).length > 0 && (
                  <div className=" absolute h-5 w-5 text-[10px] bg-red-500 aspect-square px-1 flex items-center justify-center font-semibold text-white rounded-full top-0 -right-2">
                    <span>
                      {notifications?.filter((notif) => !notif.isRead).length}
                    </span>
                  </div>
                )}
                <GoBell className=" text-theme-color font-bold text-lg md:text-xl lg:text-3xl" />
              </div>

              <Suspense fallback={<div></div>}>
                <DarkModeToggle />
              </Suspense>
            </div>
          )}
        </div>

        {!token && (
          <div className="ml-4 hidden lg:flex items-center md:ml-6 gap-9">
            {userType !== "employer" && (
              <Link
                to="/search-results"
                className=" font-semibold border-transparent border-b-[3px]  transition hover:border-theme-color py-2"
              >
                Find Jobs
              </Link>
            )}
            <Link
              to="/contactus"
              className=" font-semibold border-transparent border-b-[3px]  transition hover:border-theme-color py-2"
            >
              Contact Us
            </Link>
            <div className="hidden lg:flex items-center space-x-4">
              <Link
                to="/login"
                className=" font-semibold border-transparent border-b-[3px]  transition hover:border-theme-color py-2"
              >
                Login
              </Link>
              <Link
                to="/register"
                className="bg-primary-gradient text-white px-7 py-2 font-semibold rounded-full text-sm md:text-base"
              >
                Sign Up
              </Link>
            </div>
          </div>
        )}
        <div className="flex xl:hidden gap-2 sm:gap-4 pe-2 md:pe-10 items-center">
          <Link
            to={`/${userType}/kutumb-tokens`}
            className=" bg-white sm:border-2 hidden sm:flex  border-theme-color hover:bg-prime-gradient transition-colors text-theme-color hover:text-white sm:p-1 sm:px-3 rounded-full  items-center sm:gap-2"
          >
            <img className=" w-5 h-5 flex-shrink-0" src={kutumbToken} />
            <p className=" hidden sm:block text-xs sm:text-sm font-semibold ">
              {user?.kutumbTokens}
            </p>
          </Link>

          {token && (
            <NavLink
              to={`/${userType}`}
              className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 dark:bg-opacity-20"
            >
              <Suspense fallback={<div></div>}>
                <FiHome className="feather-home  text-gray-400 text-lg" />
              </Suspense>
            </NavLink>
          )}

          {userType === "user" && (
            <NavLink
              to={`/search-results`}
              className="p-2 hidden md:block rounded-full bg-gray-100 hover:bg-gray-200 dark:bg-opacity-20"
            >
              <Suspense fallback={<div></div>}>
                <FaSearchengin className="feather-home text-gray-400 text-lg" />
              </Suspense>
            </NavLink>
          )}

          {userType && (
            <NavLink
              to={`/searchbar-results`}
              className="p-2 md:hidden block rounded-full bg-gray-100 hover:bg-gray-200 dark:bg-opacity-20"
            >
              <IoSearch className="feather-home text-gray-400 text-lg" />
            </NavLink>
          )}
          {token && (
            <Suspense fallback={<div></div>}>
              <DarkModeToggle />
            </Suspense>
          )}

          <Suspense fallback={<div></div>}>
            <CiMenuFries
              size={27}
              className="text-zinc-700 xl:hidden xl:hover:text-theme-color hover:text-theme-color"
              onClick={
                token
                  ? toggleUserSidebar
                    ? toggleUserSidebar
                    : toggleSidebar
                  : toggleSidebar
              }
            />
          </Suspense>
        </div>
      </div>
      <Suspense fallback={<p>Loading</p>}>
        <Sidebar
          isOpen={isSidebarOpen}
          onClose={toggleSidebar}
          isMobile={isMobile}
        />
      </Suspense>
    </motion.nav>
  );
};

export default Nav;
