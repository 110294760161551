import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_API_URL || "http://localhost:3001",
});

export const axiosFormDataInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_API_URL || "http://localhost:3001",
  headers: {
    "Content-Type": "multipart/form-data",
  },
});
