// src/store/jobSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  postJob,
  fetchHRJobs,
  searchJobs,
  searchJobById,
  applyJob,
  jobsBasedOn,
  saveJobPreferences,
  shortlistUser,
  declineUser,
  updateStatusUser,
  shortlistUsers,
  declineUsers,
  editJob,
  deleteJob,
  changeJobVisibilty,
} from "./jobThunks";

const initialState = {
  jobs: [],
  candidates: [],
  loading: false,
  error: null,
  recommendedJobs: [],
  profileJobs: [],
  alertJobs: [],
  searchCriteria: {
    keyword: "",
    city: "",
    skills: [],
    datePosted: "",
    experienceLevel: 0,
    salaryRange: [0, 600000],
  },
};

const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    setKeyword: (state, action) => {
      state.searchCriteria.keyword = action.payload;
    },
    setCity: (state, action) => {
      state.searchCriteria.city = action.payload;
    },
    setDatePosted: (state, action) => {
      state.searchCriteria.datePosted = action.payload;
    },
    setExperienceLevel: (state, action) => {
      state.searchCriteria.experienceLevel = action.payload;
    },
    setSalaryRange: (state, action) => {
      state.searchCriteria.salaryRange = action.payload;
    },
    setSearchResults: (state, action) => {
      state.jobs = action.payload;
    },
    setCandidateSearchResults: (state, action) => {
      state.candidates = action.payload;
    },
    setSkills: (state, action) => {
      state.searchCriteria.skills = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postJob.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postJob.fulfilled, (state, action) => {
        state.loading = false;
        // state.jobs.push(action.payload.newJob);
        // Update localStorage after posting job
        // localStorage.setItem("jobs", JSON.stringify(state.jobs));
      })
      .addCase(postJob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //Edit job
      .addCase(editJob.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editJob.fulfilled, (state, action) => {
        state.loading = false;
        // state.jobs.push(action.payload.newJob);
        // Update localStorage after posting job
        // localStorage.setItem("jobs", JSON.stringify(state.jobs));
      })
      .addCase(editJob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //Fetch jobs
      .addCase(fetchHRJobs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHRJobs.fulfilled, (state, action) => {
        state.loading = false;
        state.jobs = action.payload; // Assuming response is an array of jobs
      })
      .addCase(fetchHRJobs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //Search Job -candidate

      .addCase(searchJobs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchJobs.fulfilled, (state, action) => {
        state.loading = false;
        state.jobs = action.payload.jobs;
      })
      .addCase(searchJobs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //Search One Job -candidate

      .addCase(searchJobById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchJobById.fulfilled, (state, action) => {
        state.loading = false;
        // state.jobs = action.payload.jobs;
      })
      .addCase(searchJobById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //Job Apply
      .addCase(applyJob.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(applyJob.fulfilled, (state, action) => {
        state.loading = false;
        // state.jobs = action.payload.jobs;
      })
      .addCase(applyJob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(jobsBasedOn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(jobsBasedOn.fulfilled, (state, action) => {
        state.loading = false;
        state.recommendedJobs = action.payload.recommendedJobs;
        state.profileJobs = action.payload.jobsBasedOnProfile;
        // state.alertJobs = action.payload.alertJobs;
      })
      .addCase(jobsBasedOn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //Job Preferences
      .addCase(saveJobPreferences.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveJobPreferences.fulfilled, (state, action) => {
        state.loading = false;
        // state.preferences = action.payload;
      })
      .addCase(saveJobPreferences.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      //Shortlist User
      .addCase(shortlistUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(shortlistUser.fulfilled, (state, action) => {
        state.loading = false;
        // state.preferences = action.payload;
      })
      .addCase(shortlistUser.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      //Decline User
      .addCase(declineUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(declineUser.fulfilled, (state, action) => {
        state.loading = false;
        // state.preferences = action.payload;
      })
      .addCase(declineUser.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      //Shortlist Multiple Users
      .addCase(shortlistUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(shortlistUsers.fulfilled, (state, action) => {
        state.loading = false;
        // state.preferences = action.payload;
      })
      .addCase(shortlistUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      //Decline Multiple Users
      .addCase(declineUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(declineUsers.fulfilled, (state, action) => {
        state.loading = false;
        // state.preferences = action.payload;
      })
      .addCase(declineUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      //Update Candidate status
      .addCase(updateStatusUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateStatusUser.fulfilled, (state, action) => {
        state.loading = false;
        // state.preferences = action.payload;
      })
      .addCase(updateStatusUser.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      //Delete a job
      .addCase(deleteJob.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteJob.fulfilled, (state, action) => {
        state.loading = false;
        // state.preferences = action.payload;
      })
      .addCase(deleteJob.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      })

      //Hide/Unhide a job
      .addCase(changeJobVisibilty.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changeJobVisibilty.fulfilled, (state, action) => {
        state.loading = false;
        // state.preferences = action.payload;
      })
      .addCase(changeJobVisibilty.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  setKeyword,
  setCity,
  setDatePosted,
  setExperienceLevel,
  setError,
  setSalaryRange,
  setSearchResults,
  setCandidateSearchResults,
  setSkills,
} = jobSlice.actions;
export const { actions, reducer } = jobSlice;
export default reducer;
