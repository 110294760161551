import React from "react";
import { useDispatch } from "react-redux";
import { createChat } from "../redux/slices/chat/chatSlice";

const MessageButton = ({ user }) => {
  const dispatch = useDispatch();

  // console.log(user);

  const handleClick = () => {
    dispatch(createChat(user)); // Create a new chat for the user
  };

  return (
    <button
      onClick={handleClick}
      className=" bg-purple-100 text-purple-900 border-theme-color border text-sm hover:bg-theme-color font-semibold hover:text-white px-4 py-1 rounded-full"
    >
      Message
    </button>
  );
};

export default MessageButton;
