// src/store/jobThunk.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../api/axios";
import { updateUser } from "../user/userSlice";

// Thunk for posting a job
export const postJob = createAsyncThunk(
  "job/postJob",
  async ({ jobData, id }, { rejectWithValue, dispatch }) => {
    try {
      console.log(jobData);
      const response = await axiosInstance.post(`/api/hr/postJob/${id}`, {
        jobData,
      });
      const { user, newJob } = response.data;
      if (user) {
        dispatch(updateUser(user));
        // localStorage.setItem("user", JSON.stringify(user));
      }
      return { newJob };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk for posting a job
export const editJob = createAsyncThunk(
  "job/editJob",
  async ({ jobData, id, jobId }, { rejectWithValue, dispatch }) => {
    try {
      console.log(jobData);
      const response = await axiosInstance.put(
        `/api/hr/editJob/${id}/${jobId}`,
        {
          jobData,
        }
      );
      const { user, updatedJob } = response.data;
      if (user) {
        dispatch(updateUser(user));
        // localStorage.setItem("user", JSON.stringify(user));
      }
      return { updatedJob };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Delete a JOb
export const deleteJob = createAsyncThunk(
  "jobs/deleteJob",
  async ({ id, jobId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.delete(
        `/api/hr/deleteJob/${id}/${jobId}`
      );

      const { hr } = response.data;
      if (hr) {
        dispatch(updateUser(hr));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Delete a JOb
export const changeJobVisibilty = createAsyncThunk(
  "jobs/changeJobVisibilty",
  async ({ id, jobId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(
        `/api/hr/changeJobVisibilty/${id}/${jobId}`
      );

      const { hr } = response.data;
      if (hr) {
        dispatch(updateUser(hr));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Thunk for fetching jobs
export const fetchHRJobs = createAsyncThunk(
  "job/fetchHRJobs",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/api/hr/jobs/${userId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk for Searching jobs-candidate
export const searchJobs = createAsyncThunk(
  "job/searchJobs",
  async ({ keyword, city }, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();

      // Only add searchText and city to the parameters if they are truthy
      if (keyword) params.append("keyword", keyword);
      if (city) params.append("city", city);
      const response = await axiosInstance.get(`/api/user/searchJobs`, {
        params,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk for fetching one job
export const searchJobById = createAsyncThunk(
  "job/searchJobById",
  async ({ jobId, userId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/api/user/fetchOneJob?jobId=${jobId}&userId=${userId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk for job apply
export const applyJob = createAsyncThunk(
  "job/applyJob",
  async ({ userId, jobId, sender }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post(`/api/user/applyJob`, {
        userId,
        jobId,
        sender,
      });

      const { user } = response.data;
      if (user) {
        dispatch(updateUser(user));
        localStorage.setItem("user", JSON.stringify(user));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk for job apply
export const jobsBasedOn = createAsyncThunk(
  "job/jobsBasedOn",
  async (userId, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get(
        `/api/user/jobsBasedOn/${userId}`
      );

      const { user } = response.data;
      if (user) {
        dispatch(updateUser(user));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveJobPreferences = createAsyncThunk(
  "jobs/saveJobPreferences",
  async (preferences, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post(`/api/user/preferences`, {
        preferences,
      });

      const { user } = response.data;
      if (user) {
        dispatch(updateUser(user));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Shortlist User
export const shortlistUser = createAsyncThunk(
  "jobs/shortlistUser",
  async ({ appId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(`/api/hr/shortlist/${appId}`);

      const { hr } = response.data;
      if (hr) {
        dispatch(updateUser(hr));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Shortlist Multiple Users
export const shortlistUsers = createAsyncThunk(
  "jobs/shortlistUsers",
  async ({ selectedApplications }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(`/api/hr/shortlist`, {
        selectedApplications,
      });

      const { hr } = response.data;
      if (hr) {
        dispatch(updateUser(hr));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Decline User
export const declineUser = createAsyncThunk(
  "jobs/declineUser",
  async ({ appId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(`/api/hr/decline/${appId}`);

      const { hr } = response.data;
      if (hr) {
        dispatch(updateUser(hr));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Decline Multiple User
export const declineUsers = createAsyncThunk(
  "jobs/declineUsers",
  async ({ selectedApplications }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(`/api/hr/decline`, {
        selectedApplications,
      });

      const { hr } = response.data;
      if (hr) {
        dispatch(updateUser(hr));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Status & Note CHange
export const updateStatusUser = createAsyncThunk(
  "jobs/updateStatusUser",
  async ({ appId, newStatus, note }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(
        `/api/hr/updateStatusUser/${appId}`,
        {
          newStatus,
        }
      );

      const { hr } = response.data;
      if (hr) {
        dispatch(updateUser(hr));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
