import { createSlice } from "@reduxjs/toolkit";
import {
  addComment,
  addReply,
  createCommunity,
  deleteComment,
  deleteCommunity,
  deletePost,
  downvote,
  editComment,
  editCommunity,
  fetchCommunities,
  getComments,
  removeMember,
  upvote,
} from "./communityThunks";

const communitySlice = createSlice({
  name: "communities",
  initialState: {
    communities: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommunities.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCommunities.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.communities = action.payload;
      })
      .addCase(fetchCommunities.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createCommunity.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.communities.push(action.payload);
      })
      .addCase(editCommunity.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteCommunity.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(removeMember.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(upvote.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(downvote.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(getComments.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(addReply.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(editComment.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        state.status = "succeeded";
      });
  },
});

export const { actions, reducer } = communitySlice;
export default reducer;
